export default {
  ko: {
    app: {
      title: "예간아이티",
      header: "헤더",
      nav: "네비게이션",
      main: "메인",
      footer: "푸터",
      button: "언어 바꾸기",
      forbiddenMessage: "허가되지 않은 계정입니다. (문의) yoon@aspaceit.com",
      unAuthMessage: "세션이 만료 되었습니다. 다시 로그인해 주세요."
    },
    home: {
      welcome: "님, 환영합니다!",
    },
    googleLogin: {
      signin: '@aspaceit 계정으로 로그인하기',
      signout: '로그아웃하기'
    },
    addon: {
      downloadHeading: 'A.eyes 다운로드',
      getAccessCodeHeading: 'A.eyes 접근 코드',
      getAccessCode: "발급 받기",
      accessCode: '접근 코드',
      copy: '클립보드에 복사하기',
      copied: '복사 완료!',
      failureGetAccessCode: '접근 코드 발급 실패',
      viewPatchNote: '패치 노트 보기',
    },
    admin: {
      addon: {
        noSupportOnMobile: '모바일 UI는 지원하지 않습니다.',
        editorPlaceholder: `패치노트를 입력하세요. (마크다운 언어 지원)\n\n#### 변경 사항\n> - 변경 사항 세부 설명 1\n>\n> - 변경 사항 세부 설명 2`
      }
    }
  },

  en: {
    app: {
      title: "Art & Space IT",
      header: "Header",
      nav: "Navigation",
      main: "Main",
      footer: "Footer",
      button: "Change language",
      forbiddenMessage: "This is forbidden account. (Contact) yoon@aspaceit.com",
      unAuthMessage: "Your session has expired. Please sign in again."
    },
    home: {
      welcome: "Welcome,",
    },
    googleLogin: {
      signin: 'Sign in with @aspaceit account',
      signout: 'Sign Out'
    },
    addon: {
      downloadHeading: 'Download A.eyes',
      getAccessCodeHeading: 'A.eyes access code',
      getAccessCode: "Get access code",
      accessCode: 'Access code',
      copy: 'Copy to clipboard',
      copied: 'Copied!',
      failureGetAccessCode: 'Failed to get access code',
      viewPatchNote: 'View patch note'
    },
    admin: {
      addon: {
        noSupportOnMobile: 'No support ui for mobile',
        editorPlaceholder: `Input a new patch note (support markdown language)\n\n#### Changes\n> - Changes detail 1\n>\n> - Changes detail 2\n\n\n\n# Markdown syntax\n\n# Heading1\n\n## Heading2\n\n### Heading3\n\n#### Heading4\n\n**Bold**\n\n*italic*\n\n\`code tag\`\n\n- unordered 1\n\n- unordered 2\n\n1. ordered 1\n\n2. ordered 2\n\n> quote\n>\n> quote
        `
      }
    }
  }
}