import React from 'react';
import { Link } from 'react-router-dom';
import {
  VStack,
  HStack,
  Button,
  Flex,
  Box,
  Spacer,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FaHome, FaFont, FaFolder, FaLock } from 'react-icons/fa';
import { MdSupervisorAccount } from 'react-icons/md';
import { useAppContext } from '../context';
import { SESSION_STATUS } from '../context/sessionStatus';
import { USER_PERMISSION } from '../context/user';
import RestrictedAccess from '../components/RestrictedAccess';

const NavStack = ({ children }) => {
  const variant = useBreakpointValue({ base: 'mobile', md: 'desktop' });

  if (variant === 'desktop') {
    return <VStack pt={5}>{children}</VStack>;
  } else {
    return <HStack height="100%">{children}</HStack>;
  }
};

const NavItem = ({ icon, to, children }) => {
  const [, dispatch] = useAppContext();
  const variant = useBreakpointValue({ base: 'mobile', md: 'desktop' });

  if (variant === 'desktop') {
    return (
      <Link
        to={to}
        style={{ width: '100%', padding: '0', margin: '0' }}
        onClick={() => dispatch({ type: 'SET_CURRENT_PATH', currentPath: to })}
      >
        <Button
          variant="link"
          _hover={{ background: 'black' }}
          width="100%"
          height="45px"
          borderRadius="none"
        >
          <Flex
            fontSize="lg"
            alignItems="center"
            width="100%"
            paddingInline={4}
          >
            <Box textAlign="center">{icon}</Box>
            <Spacer />
            <Box>{children}</Box>
          </Flex>
        </Button>
      </Link>
    );
  } else {
    return (
      <Link to={to}>
        <Button variant="link">
          <Box>{icon}</Box>
        </Button>
      </Link>
    );
  }
};

export default () => {
  const [appContext] = useAppContext();
  const { sessionStatus, user } = appContext;

  switch (sessionStatus) {
    case SESSION_STATUS.VALID:
      return (
        <NavStack>
          <NavItem to="/" icon={<FaHome />}>
            Home
          </NavItem>
          <NavItem to="/add-on" icon={<FaFont />}>
            A.eyes
          </NavItem>
          <RestrictedAccess
            from={USER_PERMISSION.PHOTOGRAPHY_STAFF}
            to={USER_PERMISSION.PHOTOGRAPHY_MANAGER}
            include={[USER_PERMISSION.MODELING_MANAGER]}
          >
            <NavItem to="/projects" icon={<FaFolder />}>
              Projects
            </NavItem>
          </RestrictedAccess>
          <RestrictedAccess
            from={USER_PERMISSION.HR_STAFF}
            to={USER_PERMISSION.HR_MANAGER}
          >
            <NavItem to="/hr" icon={<MdSupervisorAccount />}>
              HR
            </NavItem>
          </RestrictedAccess>
          <RestrictedAccess
            from={USER_PERMISSION.DEV_STAFF}
            include={[USER_PERMISSION.MODELING_MANAGER]}
          >
            <NavItem to="/admin" icon={<FaLock />}>
              Admin
            </NavItem>
          </RestrictedAccess>
        </NavStack>
      );
    default:
      return (
        <NavStack>
          <NavItem to="/" icon={<FaHome />}>
            Home
          </NavItem>
        </NavStack>
      );
  }
};
