import React, { useEffect, useState } from "react";
import { useAppContext } from '../../../../context';
import { Box, useBreakpointValue, Text, Container, Button, Flex } from "@chakra-ui/react";
import PatchNoteEditor from "./PatchNoteEditor";
import { USER_PERMISSION } from "../../../../context/user";
import ErrorLogs from "./ErrorLogs";

/**
 * What to display in Addon tab
 * - Patch Note Editor (+ renderer)
 */

const MANAGEMENT_TABS = {
  PATCH_NOTE: 1,
  ERROR_LOGS: 2,
}

const AddonTabButtons = ({ handler }) => {
  const [{ user: { permission } }] = useAppContext()

  const tabs = { ...MANAGEMENT_TABS };

  if (permission < USER_PERMISSION.ADMIN) delete tabs.ERROR_LOGS;

  return (
    <>
      {Object.keys(tabs).map(tab => {
        return (
          <Button onClick={() => handler(MANAGEMENT_TABS[tab])} key={tab}>{tab}</Button>
        )
      })}
    </>
  )
}

const AddonTab = ({ tab, adminTabHandler }) => {
  switch (tab) {
    case MANAGEMENT_TABS.PATCH_NOTE:
      return <PatchNoteEditor adminTabHandler={adminTabHandler} />
    case MANAGEMENT_TABS.ERROR_LOGS:
      return <ErrorLogs />
    default:
      return <PatchNoteEditor />
  }
}

export default ({ adminTabHandler }) => {
  const [appContext,] = useAppContext();
  const variant = useBreakpointValue({ base: 'mobile', xl: 'desktop' });
  const translations = appContext.translations.admin.addon;
  const [tab, setTab] = useState(MANAGEMENT_TABS.PATCH_NOTE);

  if (variant === 'desktop') {
    return (
      <>
        <Flex gap={2} padding={5}>
          <AddonTabButtons handler={setTab} />
        </Flex>
        <Container centerContent={true}>
          <AddonTab tab={tab} adminTabHandler={adminTabHandler} />
        </Container>
      </>

    )
  } else return (
    <Text>
      {translations.noSupportOnMobile}
    </Text>
  )
}