import React from 'react';
import {
  GoogleLogin,
  googleLogout,
  GoogleOAuthProvider,
} from '@react-oauth/google';
import { useAppContext } from '../context';
import { SESSION_STATUS } from '../context/sessionStatus';
import axios from 'axios';
import { useBreakpointValue } from '@chakra-ui/react';

const SignInWithGoogle = () => {
  const [appContext, dispatch] = useAppContext();
  const variant = useBreakpointValue({ base: 'mobile', md: 'desktop' });

  const onSuccess = async res => {
    const idToken = res.credential;
    try {
      const {
        data: { name, team, permission, picUrl },
      } = await axios({
        method: 'POST',
        url: '/api/users/signin',
        data: {
          idToken: idToken,
        },
      });
      dispatch({
        type: 'SET_SESSION_STATUS',
        sessionStatus: SESSION_STATUS.VALID,
      });
      dispatch({
        type: 'SET_USER_INFO',
        user: { name, team, permission, picUrl },
      });
    } catch (err) {
      const code = err.response ? err.response.status : 500;
      if (code === SESSION_STATUS.FORBIDDEN) {
        dispatch({
          type: 'SET_SESSION_STATUS',
          sessionStatus: SESSION_STATUS.FORBIDDEN,
        });
      } else {
        // alert('로그인에 실패하였습니다.');
        console.log(err);
        dispatch({
          type: 'SET_SESSION_STATUS',
          sessionStatus: SESSION_STATUS.UNAUTHORIZED,
        });
      }
    }
  };

  const onError = res => {
    // alert('로그인에 실패하였습니다.');
    console.log(res.error + ' ' + res.details);
    dispatch({
      type: 'SET_SESSION_STATUS',
      sessionStatus: SESSION_STATUS.UNAUTHORIZED,
    });
  };

  return (
    <GoogleLogin
      onSuccess={onSuccess}
      onError={onError}
      type={variant === 'desktop' ? 'standard' : 'icon'}
    />
  );
};

const SignOutWithGoogle = () => {
  const [appContext, dispatch] = useAppContext();
  const variant = useBreakpointValue({ base: 'mobile', md: 'desktop' });

  const onClick = async () => {
    googleLogout();
    try {
      await axios({
        method: 'POST',
        url: '/api/users/signout',
      });
      dispatch({
        type: 'SET_SESSION_STATUS',
        sessionStatus: SESSION_STATUS.UNAUTHORIZED,
      });
      dispatch({
        type: 'SET_USER_INFO',
        user: {},
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div onClick={onClick} style={{ cursor: 'pointer' }}>
      {appContext.translations.googleLogin.signout}
    </div>
  );
};

export default () => {
  const clientId =
    '168882356105-1crb064ashtalecb1drgkb9mvunacs2m.apps.googleusercontent.com';
  const [appContext] = useAppContext();
  const { sessionStatus } = appContext;

  return (
    <GoogleOAuthProvider clientId={clientId}>
      {sessionStatus !== SESSION_STATUS.VALID ? (
        <SignInWithGoogle />
      ) : (
        <SignOutWithGoogle />
      )}
    </GoogleOAuthProvider>
  );
};
