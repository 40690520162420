import React, { useEffect, useRef } from "react";
import { Select } from '@chakra-ui/react'
import { USER_PERMISSION } from "../context/user";
import { useAppContext } from "../context";

const getPermissionList = () => {
  const list = [];
  for (let permission of Object.keys(USER_PERMISSION)) {
    if (permission === 'toString') continue;
    list.push(permission);
  }
  return list;
}

export default () => {
  const [appContext, dispatch] = useAppContext();
  const permissions = useRef(getPermissionList());
  const selectRef = useRef();

  useEffect(() => {
    if (selectRef.current) selectRef.current.value = appContext.user.permission;
  }, [appContext.user.permission])

  if (
    process.env.NODE_ENV === 'development' &&
    appContext.user.permission
  ) {
    return (
      <Select
        ref={selectRef}
        width="sm"
        onChange={() => {
          let team = USER_PERMISSION.toString(Number(selectRef.current.value)).split("_")[0];
          console.log(team)
          dispatch({
            type: 'SET_USER_INFO',
            user: {
              ...appContext.user,
              team: team,
              permission: Number(selectRef.current.value)
            }
          })
        }}
      >
        {permissions.current.map(permission =>
          <option
            value={USER_PERMISSION[permission]}
            key={`selector-${permission}`}
          >
            {permission} ({USER_PERMISSION[permission]})
          </option>
        )}
      </Select>
    )
  }
}