import { IconButton } from '@chakra-ui/react';
import React from 'react';
import { useAppContext } from '../context';
import { MdLanguage } from 'react-icons/md'

export const LanguageSwitcher = props => {
  const [appContext, dispatch] = useAppContext();

  const toggleLanaguage = () => {
    dispatch({
      type: 'SET_LANGUAGE',
      language: appContext.language === 'ko' ? 'en' : 'ko'
    })
  }

  return (
    <IconButton
      size="md"
      fontSize="lg"
      aria-label={`Switch language`}
      variant="ghost"
      marginLeft="2"
      onClick={toggleLanaguage}
      icon={<MdLanguage size={22} />}
      {...props}
    />
  )
}