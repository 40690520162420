import { Text } from '@chakra-ui/react';

const statusEnum = {
  null: '시작되지 않음',
  0: '할당됨',
  10: '모델링 시작',
  11: '모델링 중',
  19: '모델링 완료',
  20: '페인팅 시작',
  21: '페인팅 중',
  29: '페인팅 완료',
};

export default ({ status, ...rest }) => {
  const color = () => {
    switch (status) {
      case 10:
        return 'red.300';
      case 11:
        return 'orange.300';
      case 19:
        return 'yellow.300';
      case 20:
        return 'green.300';
      case 21:
        return 'blue.300';
      case 29:
        return 'purple.300';
      case null:
        return 'gray';
      case 0:
      default:
        return;
    }
  };

  return (
    <Text color={color()} {...rest}>
      {statusEnum[status]}
    </Text>
  );
};
