import { SESSION_STATUS } from './sessionStatus';
import { Cookies } from 'react-cookie';
import { USER_PERMISSION } from './user';

const cookies = new Cookies();

const session = process.env.REACT_APP_DEV_SESSION;
const devModeTime = 600;

const devUser = {
  name: 'Dev',
  team: 'ADMIN',
  permission: USER_PERMISSION.ADMIN,
};

export default dispatch => {
  dispatch({
    type: 'SET_USER_INFO',
    user: devUser,
  });
  dispatch({
    type: 'SET_SESSION_STATUS',
    sessionStatus: SESSION_STATUS.VALID,
  });

  cookies.set('session', session, {
    maxAge: devModeTime,
    domain: 'localhost',
  });
};
