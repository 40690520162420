import {
  Text,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Heading,
  Box,
  Flex,
  Spacer,
  FormLabel,
  Input,
  useOutsideClick,
  Divider,
  HStack,
  VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import RestrictedAccess from '../../components/RestrictedAccess';
import { USER_PERMISSION } from '../../context/user';
import { convertDateToLocaleString } from '../../utils/date';
import ProjectStatus from './ProjectStatus';

/**
 * @param field <br>
 * Change non-input component to input component
 * - 'field' is from Formik \<Field\>
 */
const Editable = ({ children: _children, field }) => {
  const { children, ...props } = _children.props;
  const boxRef = useRef();
  const inputRef = useRef();
  const [editMode, setEditMode] = useState(false);

  const onOutsideClick = e => {
    if (editMode && e.target !== inputRef.current) {
      setEditMode(false);
    }
  };

  useOutsideClick({ ref: boxRef, handler: onOutsideClick });

  useEffect(() => {
    if (!editMode) {
      boxRef.current.innerText = field.value;
    }
  }, [editMode]);

  return (
    <>
      {editMode ? (
        <Input ref={inputRef} {...props} {...field} />
      ) : (
        <Box
          cursor="pointer"
          ref={boxRef}
          onClick={() => setEditMode(true)}
          {...props}
        >
          {children}
        </Box>
      )}
    </>
  );
};

const WorkHistory = ({ histories }) => {
  return (
    <>
      <FormLabel opacity="0.5" fontSize="sm" m={0}>
        작업 내역
      </FormLabel>
      <Divider marginBlock={1} />
      {histories.length > 0 ? (
        histories.map(history => {
          return (
            <Flex key={'work' + history.id}>
              <ProjectStatus status={history.status} w="20%" />
              <Spacer />
              <Text w="20%" textAlign="center">
                {history.worker}
              </Text>
              <Spacer />
              <Text>{convertDateToLocaleString(history.created_at)}</Text>
            </Flex>
          );
        })
      ) : (
        <ProjectStatus status={null} />
      )}
      <Divider marginTop={1} />
    </>
  );
};

export default ({ project, isOpen, onClose, setNeedRerender }) => {
  const [histories, setHistory] = useState([]);
  const [historyPatched, setHistoryPatched] = useState(false);

  const translateFieldName = field => {
    switch (field) {
      case 'orderer':
        return '발주사';
      case 'address':
        return '주소';
      case 'description':
        return '설명';
      default:
        return field;
    }
  };

  const onSubmit = async (values, actions) => {
    // console.log(values);
    await axios({
      url: `/api/projects/${project.id}`,
      method: 'put',
      data: values,
    });

    actions.setSubmitting(false);
    actions.resetForm();

    setNeedRerender(prev => prev + 1);
    onClose();
  };

  const getWorkHistory = async () => {
    let { data: histories } = await axios({
      url: `/api/projects/${project.id}/history`,
      method: 'get',
    });
    console.log(histories);
    setHistory(histories);
  };

  useEffect(() => {
    if (isOpen && !historyPatched) {
      getWorkHistory();
      setHistoryPatched(true);
    }
  }, [isOpen]);

  return (
    <Formik
      initialValues={{ ...project }}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      {props => (
        <Form>
          <Modal
            isOpen={isOpen}
            onClose={() => {
              props.handleReset();
              onClose();
            }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader w="95%">
                <Field name="title">
                  {({ field, form }) => (
                    <Editable field={field}>
                      <Heading fontSize="2xl">{project.title}</Heading>
                    </Editable>
                  )}
                </Field>
                <Box marginInline={1} marginTop={1}>
                  <Field name="title_en">
                    {({ field, form }) => (
                      <Editable field={field}>
                        <Text fontSize="sm" opacity="0.3">
                          {project.title_en}
                        </Text>
                      </Editable>
                    )}
                  </Field>
                  <Flex marginTop={2} fontSize="sm">
                    <Text>{project.author}</Text>
                    <Spacer />
                    <Text>{convertDateToLocaleString(project.created_at)}</Text>
                  </Flex>
                </Box>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody mt={6} mb={6} fontSize="sm">
                {Object.keys(project).map(projectField => {
                  if (
                    projectField === 'id' ||
                    projectField === 'author_id' ||
                    projectField === 'author' ||
                    projectField === 'title' ||
                    projectField === 'title_en' ||
                    projectField === 'created_at' ||
                    projectField === 'status'
                  )
                    return null;

                  return (
                    <Flex key={project.id + projectField}>
                      <FormLabel w="13%" opacity="0.5" fontSize="sm">
                        {translateFieldName(projectField)}
                      </FormLabel>
                      <Field name={projectField}>
                        {({ field, form }) => (
                          <Editable field={field}>
                            <Text w="87%" fontSize="sm">
                              {project[projectField]
                                ? project[projectField]
                                : '<' + projectField + '>'}
                            </Text>
                          </Editable>
                        )}
                      </Field>
                    </Flex>
                  );
                })}
                <RestrictedAccess
                  include={[
                    USER_PERMISSION.MODELING_MANAGER,
                    USER_PERMISSION.ADMIN_SUB,
                    USER_PERMISSION.DEV_MANAGER,
                    USER_PERMISSION.DEV_STAFF,
                  ]}
                >
                  <WorkHistory histories={histories} />
                </RestrictedAccess>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={props.handleSubmit}
                  type="submit"
                  isLoading={props.isSubmitting}
                  colorScheme="blue"
                  size="sm"
                >
                  변경
                </Button>
                <Button
                  onClick={() => {
                    props.handleReset();
                    onClose();
                  }}
                  size="sm"
                  marginLeft={2}
                >
                  취소
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
