const type = 'SET_SESSION_STATUS';

export const SESSION_STATUS = {
  VALID: 200,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403
}

const initState = {
  sessionStatus: SESSION_STATUS.UNAUTHORIZED
}

const handler = (state, action) => {
  const { sessionStatus } = action;
  return {
    ...state,
    sessionStatus
  }
}

export default { type, initState, handler }