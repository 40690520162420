import React, { useContext, useReducer } from "react";
import language from './language';
import user from "./user";
import sessionStatus from './sessionStatus';
import path from './path';

const initContext = {
  ...language.initState,
  ...user.initState,
  ...sessionStatus.initState,
  ...path.initState,
}

const AppContext = React.createContext();

const useAppContext = () => useContext(AppContext);

const AppContextProvider = ({ children }) => {
  const [appContext, dispatch] = useReducer(reducer, initContext);

  return (
    <AppContext.Provider value={[appContext, dispatch]} >
      {children}
    </AppContext.Provider>
  )
}

const reducer = (state, action) => {
  switch (action.type) {
    case language.type:
      return language.handler(state, action)
    case sessionStatus.type:
      return sessionStatus.handler(state, action)
    case user.type:
      return user.handler(state, action)
    case path.type:
      return path.handler(state, action)
    default:
      return state;
  }
}

export { useAppContext, AppContextProvider }