import React, { useEffect, useRef, useState } from 'react';
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useDisclosure,
  useColorMode,
  Text,
} from '@chakra-ui/react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import axios from 'axios';
import { convertDateToLocaleString } from '../../utils/date';
import Project from './Project';
import ProjectStatus from './ProjectStatus';

const ProjectRow = ({ project, fields, setNeedRerender }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  return (
    <>
      <Tr
        onClick={onOpen}
        _hover={{
          background: colorMode === 'light' ? 'gray.100' : 'gray.500',
          cursor: 'pointer',
        }}
        userSelect="none"
      >
        {fields.map(field => {
          const fieldValue = project[field];
          if (field === 'created_at') {
            return (
              <Td key={project.id + field}>
                {convertDateToLocaleString(fieldValue)}
              </Td>
            );
          } else if (field === 'status') {
            return (
              <Td key={project.id + field}>
                <ProjectStatus status={fieldValue} />
              </Td>
            );
          } else {
            return <Td key={project.id + field}>{fieldValue}</Td>;
          }
        })}
      </Tr>
      <Project
        project={project}
        isOpen={isOpen}
        onClose={onClose}
        setNeedRerender={setNeedRerender}
      />
    </>
  );
};

export default ({ needRerender, setNeedRerender }) => {
  const [projects, setProjects] = useState([]);
  const [sortingField, setSortingField] = useState('created_at');
  const [sortingDirection, setSortingDirection] = useState('desc');
  const fields = useRef([]);

  const getProjects = async () => {
    let { data: projects } = await axios({
      url: '/api/projects',
      method: 'get',
    });
    fields.current = Object.keys(projects[0]).filter(
      // skip field
      key =>
        key !== 'id' &&
        key !== 'author_id' &&
        key !== 'address' &&
        key !== 'description'
    );

    setProjects([...sort(projects, sortingField, sortingDirection)]);
  };

  const sort = (projects, field, direction) => {
    if (field) {
      field = field.toLowerCase();
      projects = projects.sort((a, b) => {
        if (!a[field]) return 0;
        if (!b[field]) return -1;
        if (direction && direction.toLowerCase() === 'desc') {
          return b[field] > a[field] ? 0 : -1;
        } else {
          return a[field] > b[field] ? 0 : -1;
        }
      });
    }
    return projects;
  };

  useEffect(() => {
    getProjects();
  }, [needRerender]);

  useEffect(() => {
    setProjects([...sort(projects, sortingField, sortingDirection)]);
  }, [sortingField, sortingDirection]);

  return (
    <>
      <TableContainer mt={5}>
        <Table size="sm">
          <Thead>
            <Tr>
              {fields.current.map(field => (
                <Th
                  onClick={() => {
                    setSortingField(field);
                    setSortingDirection(prev =>
                      prev === 'desc' ? 'asc' : 'desc'
                    );
                  }}
                  key={field}
                  cursor="pointer"
                  userSelect="none"
                >
                  {field}
                  {sortingField === field &&
                    (sortingDirection === 'desc' ? (
                      <MdKeyboardArrowDown
                        style={{ display: 'inline', verticalAlign: 'middle' }}
                      />
                    ) : (
                      <MdKeyboardArrowUp
                        style={{ display: 'inline', verticalAlign: 'middle' }}
                      />
                    ))}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {projects.map(project => (
              <ProjectRow
                project={project}
                fields={fields.current}
                setNeedRerender={setNeedRerender}
                key={project.id}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};
