import React, { useState } from 'react';
import RestrictedAccess from '../../components/RestrictedAccess';
import { USER_PERMISSION } from '../../context/user';
import List from './List';
import New from './New';

export default () => {
  const [rerender, setRerender] = useState(0);

  return (
    <RestrictedAccess
      from={USER_PERMISSION.PHOTOGRAPHY_STAFF}
      to={USER_PERMISSION.PHOTOGRAPHY_MANAGER}
      include={[USER_PERMISSION.MODELING_MANAGER]}
    >
      <New setNeedRerender={setRerender} />
      <List needRerender={rerender} setNeedRerender={setRerender} />
    </RestrictedAccess>
  );
};
