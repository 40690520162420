import React, { useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
  ChakraProvider,
  Grid,
  GridItem,
  Text,
  Flex,
  Spacer,
  useBreakpointValue,
  theme,
  Box,
  Image,
  Avatar,
  useColorMode,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './components/ColorModeSwitcher';
import { LanguageSwitcher } from './components/LanguageSwitcher';
import { AppContextProvider, useAppContext } from './context';
import { SESSION_STATUS } from './context/sessionStatus';
import NavBar from './views/NavBar';
import Home from './views/Home';
import Addon from './views/Addon';
import GoogleLogin from './components/GoogleLogin';
import { FaRegCopyright } from 'react-icons/fa';
import Admin from './views/Admin';
import enableDevMode from './context/enableDevMode';
import { USER_PERMISSION } from './context/user';
import Projects from './views/Projects';
import PermissionSelector from './components/PermissionSelector';
import HR from './views/HR';

function AppView() {
  const variant = useBreakpointValue({ base: 'mobile', md: 'desktop' });
  const { colorMode } = useColorMode();
  const [appContext, dispatch] = useAppContext();
  const translations = appContext.translations.app;
  const { sessionStatus, user } = appContext;

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      enableDevMode(dispatch);
    } else {
      checkSession();
    }
  }, []);

  const checkSession = async () => {
    try {
      const { status, data } = await axios({
        method: 'GET',
        url: '/api/home/checkSession',
        headers: { 'Cache-Control': 'no-cache' },
      });

      if (status === SESSION_STATUS.VALID) {
        const { name, team, permission, pic_url: picUrl } = data;
        dispatch({
          type: 'SET_USER_INFO',
          user: { name, team, permission, picUrl },
        });
        dispatch({
          type: 'SET_SESSION_STATUS',
          sessionStatus: SESSION_STATUS.VALID,
        });
      }
    } catch (err) {
      const code = err.response.status;
      if (code === SESSION_STATUS.FORBIDDEN) {
        dispatch({
          type: 'SET_SESSION_STATUS',
          sessionStatus: SESSION_STATUS.FORBIDDEN,
        });
      } else {
        dispatch({
          type: 'SET_SESSION_STATUS',
          sessionStatus: SESSION_STATUS.UNAUTHORIZED,
        });
      }
    }
  };

  const displayMainView = () => {
    switch (sessionStatus) {
      case SESSION_STATUS.VALID:
        return (
          <Box p={7}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/add-on" element={<Addon />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/hr" element={<HR />} />
              <Route path="/admin" element={<Admin />} />
            </Routes>
          </Box>
        );
      case SESSION_STATUS.FORBIDDEN:
        return (
          <Box p={7}>
            <Text>{translations.forbiddenMessage}</Text>
          </Box>
        );
      case SESSION_STATUS.UNAUTHORIZED:
      default:
        return (
          <Box p={7}>
            <Text>{translations.unAuthMessage}</Text>
          </Box>
        );
    }
  };

  return (
    <Router>
      <Grid
        templateAreas={
          variant === 'desktop'
            ? // desktop
              ` "header header"
              "nav main"
              "footer footer"`
            : // mobile
              ` "nav nav"
              "main main"
              "footer footer"`
        }
        gridTemplateRows={'50px 1fr 30px'}
        gridTemplateColumns={variant === 'desktop' ? '150px 1fr' : ''}
        minH="100vh"
      >
        {variant === 'desktop' ? (
          // desktop
          <>
            <GridItem borderBottom="1px" borderColor="gray.300" area={'header'}>
              <Flex alignItems="center" height="100%">
                <Image
                  src={`${process.env.PUBLIC_URL}/full_logo_${appContext.language}_${colorMode}.png`}
                  height="100%"
                  fit="scale-down"
                  marginInline={3}
                  userSelect="none"
                />
                <Spacer />
                <Avatar src={user.picUrl} size="sm" marginInline={3} />
                <PermissionSelector />
                <GoogleLogin />
                {/* {process.env.NODE_ENV !== 'development' && <GoogleLogin />} */}
                <ColorModeSwitcher />
                <LanguageSwitcher />
              </Flex>
            </GridItem>
            <GridItem borderRight="1px" borderColor="gray.300" area={'nav'}>
              <NavBar />
            </GridItem>
          </>
        ) : (
          // mobile
          <GridItem borderBottom="1px" borderColor="gray.300" area={'nav'}>
            <Flex alignItems="center" height="100%">
              <NavBar />
              <Spacer />
              <GoogleLogin />
              {/* {process.env.NODE_ENV !== 'development' && <GoogleLogin />} */}
              <ColorModeSwitcher />
              <LanguageSwitcher />
            </Flex>
          </GridItem>
        )}
        <GridItem area={'main'}>{displayMainView()}</GridItem>
        <GridItem area={'footer'}>
          <Flex alignItems="center" justifyContent="center" height="100%">
            <Text marginInline={1}>Copyright</Text>
            <FaRegCopyright />
            <Text marginInline={1}>2022 Art&SpaceIT, Inc.</Text>
          </Flex>
        </GridItem>
      </Grid>
    </Router>
  );
}

function App() {
  return (
    <ChakraProvider theme={theme} resetCSS={true}>
      <AppContextProvider>
        <AppView />
      </AppContextProvider>
    </ChakraProvider>
  );
}

export default App;
