import React, { useEffect, useRef, useState } from "react";
import { useAppContext } from '../../../context';
import { Box, Avatar, useColorModeValue, Table, Thead, Tr, Th, Tbody, Td, ButtonGroup, IconButton, Select, useToast, Popover, PopoverTrigger, Portal, PopoverContent, PopoverArrow, PopoverHeader, PopoverCloseButton, PopoverBody, Button, PopoverFooter, useDisclosure } from "@chakra-ui/react";
import { BsFillTrashFill, BsCheck } from 'react-icons/bs';
import { AiFillEdit } from 'react-icons/ai';
import axios from "axios";
import { USER_PERMISSION } from "../../../context/user";
import { convertDateToLocaleString } from "../../../utils/date";

const User = ({ id, user: initUser, fields, deleteUser }) => {
  const color1 = useColorModeValue("gray.400", "gray.400");
  const color2 = useColorModeValue("gray.400", "gray.400");
  const [appContext,] = useAppContext();
  const [user, setUserState] = useState(initUser);
  const [ready, setReady] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const toast = useToast();
  const selectPermission = useRef(null);
  const selectSiteApproval = useRef(null);
  const { isOpen: isDeleteOpen, onToggle: onDeleteToggle, onClose: onDeleteClose } = useDisclosure()

  const generateAvailablePermissions = () => {
    const permission = appContext.user.permission;
    return Object.keys(USER_PERMISSION).filter(key => permission > USER_PERMISSION[key])
  }

  const renderUserItem = (field) => {
    switch (field) {
      case "name":
        return (
          <Box>
            <Avatar src={user.pic_url} size="xs" marginEnd={1} />
            <Box verticalAlign="middle" display="inline">
              {user[field]}
            </Box>
          </Box>
        )
      case "permission":
        if (editMode) {
          return (
            <Select ref={selectPermission} size="sm" defaultValue={USER_PERMISSION[user.permission]} maxWidth="80%">
              {generateAvailablePermissions().map((permission, idx) => {
                return (
                  <option
                    value={USER_PERMISSION[permission]}
                    key={idx + id}
                  >
                    {permission} ({USER_PERMISSION[permission]})
                  </option>
                )
              }
              )}
            </Select>
          )
        }
      case "site_approval":
        if (editMode) {
          return (
            <Select ref={selectSiteApproval} size="sm" defaultValue={user.site_approval === "YES" ? "1" : "0"}>
              <option value="1">YES</option>
              <option value="0">NO</option>
            </Select>
          )
        }
      default:
        return (
          <Box verticalAlign="middle" display="inline">
            {user[field]}
          </Box>
        )
    }
  }

  const toggleEditMode = async (e) => {
    if (editMode) {
      const permission = selectPermission.current?.value ?? user.permission;
      const approved = selectSiteApproval.current?.value ?? user.approved;

      const { data: newUser } = await axios({
        url: `/api/users/${id}`,
        method: 'PUT',
        data: {
          user: { permission, approved }
        }
      })

      updateUser(newUser)
    }

    if (appContext.user.permission > user.permissionNum)
      setEditMode(prev => !prev)
    else
      toast({
        description: '당신보다 권한이 낮은 유저만 변경할 수 있습니다.',
        position: 'top',
        status: 'error',
        isClosable: true,
      })
  }

  const updateUser = (user) => {
    const converted = {
      site_approval: user.approved ? "YES" : "NO",
      permissionNum: user.permission,
      permission: USER_PERMISSION.toString(user.permission),
      last_logged_at: convertDateToLocaleString(user.logged_at)
    }
    setUserState(prev => { return { ...prev, ...converted } })
  }

  const onClickDeleteUser = () => {
    if (user.permissionNum >= appContext.user.permission) {
      toast({
        description: '당신보다 권한이 낮은 유저만 삭제할 수 있습니다.',
        position: 'top',
        status: 'error',
        isClosable: true,
      })
    } else {
      toast({
        description: `${user.name}님 삭제했습니다.`,
        position: 'top',
        status: 'success',
        isClosable: true,
      })
      deleteUser(id);
    }
    onDeleteToggle();
  }

  useEffect(() => {
    updateUser(user)
    setReady(true)
  }, [])

  if (ready)
    return (
      <Tr
        display={{
          base: "grid",
          md: "table-row",
        }}
        sx={{
          "@media print": {
            display: "table-row",
          },
          gridTemplateColumns: "minmax(0px, 35%) minmax(0px, 65%)",
          gridGap: "10px",
        }}
      >
        {fields.map((field) => {
          return (
            <React.Fragment key={`${id}${field}`}>
              <Td
                display={{
                  base: "table-cell",
                  md: "none",
                }}
                sx={{
                  "@media print": {
                    display: "none",
                  },
                  textTransform: "uppercase",
                  color: color1,
                  fontSize: "xs",
                  fontWeight: "bold",
                  letterSpacing: "wider",
                  fontFamily: "heading",
                }}
              >
                {field}
              </Td>
              <Td
                color={"gray.500"}
                fontSize="md"
                fontWeight="hairline"
              >
                {renderUserItem(field)}
              </Td>
            </React.Fragment>
          )
        })}
        <Td
          display={{
            base: "table-cell",
            md: "none",
          }}
          sx={{
            "@media print": {
              display: "none",
            },
            textTransform: "uppercase",
            color: color2,
            fontSize: "xs",
            fontWeight: "bold",
            letterSpacing: "wider",
            fontFamily: "heading",
          }}
        >
          Actions
        </Td>
        <Td>
          <ButtonGroup variant="solid" size="sm" spacing={3}>
            <IconButton
              onClick={toggleEditMode}
              colorScheme={editMode ? "green" : "gray"}
              icon={editMode ? <BsCheck /> : <AiFillEdit />}
              aria-label="Edit"
            />
            <Popover size="sm" isOpen={isDeleteOpen} onClose={onDeleteClose}>
              <PopoverTrigger>
                <IconButton
                  onClick={onDeleteToggle}
                  colorScheme="red"
                  variant="outline"
                  icon={<BsFillTrashFill />}
                  aria-label="Delete"
                />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                  {user.name} 삭제 하시겠습니까?
                </PopoverBody>
                <PopoverFooter>
                  <Button onClick={onClickDeleteUser} colorScheme='red'>삭제</Button>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
          </ButtonGroup>
        </Td>
      </Tr >
    );
}

const UserTable = ({ fields, children }) => {
  return (
    <Table
      w="full"
      bg="white"
      _dark={{
        bg: "gray.800",
      }}
      display={{
        base: "block",
        md: "table",
      }}
      sx={{
        "@media print": {
          display: "table",
        },
      }}
    >
      <Thead
        display={{
          base: "none",
          md: "table-header-group",
        }}
        sx={{
          "@media print": {
            display: "table-header-group",
          },
        }}
      >
        <Tr>
          {fields.map((x) => (
            <Th key={x}>{x}</Th>
          ))}
          <Th>Actions</Th>
        </Tr>
      </Thead>
      <Tbody
        display={{
          base: "block",
          md: "table-row-group",
        }}
        sx={{
          "@media print": {
            display: "table-row-group",
          },
        }}
      >
        {children}
      </Tbody>
    </Table>
  );
}

export default () => {
  const [{ user: { permission, team } },] = useAppContext();
  const [users, setUsers] = useState([]);
  const toast = useToast();

  let fields = ['name', 'team', 'permission'];
  if (permission === USER_PERMISSION.ADMIN) {
    fields.push('site_approval')
    fields.push('last_logged_at')
  }

  const getUsers = async () => {
    try {
      let url;
      if (permission < USER_PERMISSION.MANAGE_A_EYES_PATCH_NOTE) {
        url = `/api/users?team=${team}`;
      } else {
        url = '/api/users';
      }
      const { data: users } = await axios({ method: 'GET', url });
      setUsers(users);
    } catch (err) {
      toast({
        description: err.response.data,
        position: 'top',
        status: 'error',
        isClosable: true,
      })
    }
  }

  const deleteUser = async (id) => {
    try {
      await axios({ method: 'DELETE', url: `/api/users/${id}` });
      const newUsers = users.filter(user => user.id !== id);
      setUsers(newUsers);
    } catch (err) {
      toast({
        description: err.response.data,
        position: 'top',
        status: 'error',
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    getUsers();
  }, [])

  return (
    <>
      <UserTable fields={fields}>
        {
          users.map(user => (
            <User
              user={user} fields={fields} id={user.id} key={user.id}
              deleteUser={deleteUser}
            />
          ))
        }
      </UserTable>
    </>
  )
}
