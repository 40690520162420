import translations from "./translations"

const localLanguage = localStorage.getItem('language') || 'ko';

const type = 'SET_LANGUAGE';

const initState = {
  language: localLanguage,
  translations: translations[localLanguage]
}

const handler = (state, action) => {
  const { language } = action;
  localStorage.setItem('language', language);
  return {
    ...state,
    language: language,
    translations: translations[language]
  }
}

export default { type, initState, handler }