import { HStack, VStack, Text, Textarea, Box, StackDivider, useColorMode, Button, FormControl, FormLabel, Input, FormErrorMessage, RadioGroup, Radio, Stack, Select, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, useDisclosure, Slide, SlideFade, Collapse, VisuallyHiddenInput, VisuallyHidden, Container, useToast } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useAppContext } from "../../../../context";
import MarkdownRenderer from "../../../../components/MarkdownRenderer";
import { Field, Form, Formik } from "formik";
import axios from "axios";

const minH = 500;

const Editor = ({ input, handler, formikHandler }) => {
  const [appContext] = useAppContext();
  const translations = appContext.translations.admin.addon;

  return (
    <Field name="patchNote">
      {({ field, form }) => (
        <FormControl onChange={formikHandler}>
          <Textarea
            {...field}
            value={input}
            onChange={handler}
            resize="none"
            placeholder={translations.editorPlaceholder}
            minH={minH}
            minW="xs"
            maxW="md"
          />
          <FormErrorMessage>{form.errors.name}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  )
}

const Renderer = ({ children }) => {
  const [appContext] = useAppContext();
  const translations = appContext.translations.admin.addon;
  const { colorMode } = useColorMode();
  return (
    <Box
      border="1px"
      borderColor={colorMode === "light" ? "gray.200" : "gray.600"}
      borderRadius="md"
      height={minH}
      minW="xs"
      maxW="md"
      overflowY='scroll'
    >
      <MarkdownRenderer overflowY='scroll' >
        {children ? children : translations.editorPlaceholder}
      </MarkdownRenderer>
    </Box >
  )
}

const PatchNoteSetting = ({ stage, setStage, patchNotes, setField, isSubmitting, setInput }) => {
  const [versionList, setVersionList] = useState([]);
  const [newVersion, setNewVersion] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const selectRef = useRef();

  const sortVersion = (versions) => {
    const unSorted = Object.keys(versions);
    const sorted = unSorted
      .map(a => a.replace(/\d+/g, n => +n + 100000)).sort().reverse()
      .map(a => a.replace(/\d+/g, n => +n - 100000));
    return sorted;
  }

  useEffect(() => {
    if (!Object.keys(patchNotes).length) return;
    if (!Object.keys(patchNotes[stage]).length) return;

    const updatedVersions = sortVersion(patchNotes[stage]);
    const latestVersion = updatedVersions[0].split('.');

    const newVersion = [
      latestVersion[0], latestVersion[1], Number(latestVersion[2]) + 1
    ];
    setField('version', newVersion.join("."));
    setNewVersion([...newVersion]);
    setVersionList([...updatedVersions]);
    onOpen();
  }, [patchNotes, stage])

  return (
    <VStack>
      <Field name="stage">
        {({ field, form }) => (
          <FormControl>
            <HStack mb={5}>
              <FormLabel marginBottom={0} whiteSpace="nowrap">Stage</FormLabel>
              <RadioGroup
                {...field}
                onChange={e => {
                  setStage(e);
                  form.setFieldValue('stage', e);
                }}
                value={stage}
              >
                <Stack direction='row' gap={3}>
                  <Radio value='release_ko'><Text whiteSpace="nowrap">Release ko.</Text></Radio>
                  <Radio value='release_en'><Text whiteSpace="nowrap">Release en.</Text></Radio>
                  <Radio value='test'><Text whiteSpace="nowrap">Test</Text></Radio>
                </Stack>
              </RadioGroup>
            </HStack>
            <FormErrorMessage>{form.errors.name}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <HStack>
        <FormLabel marginBottom={0} whiteSpace="nowrap">Version</FormLabel>
        <Select
          ref={selectRef}
          onChange={() => {
            const selectedVersion = selectRef.current.value;
            if (selectedVersion === "new") onOpen();
            else {
              onClose();
              setInput(patchNotes[stage][selectedVersion]);
              setField('version', selectedVersion)
            }
          }}
          defaultValue="new"
        >
          <option value="new">new</option>
          {versionList.map(version => <option key={version} value={version}>{version}</option>)}
        </Select>
      </HStack>
      <SlideFade in={isOpen} offsetY="-20px" >
        <Field>
          {({ field, form }) => (
            <FormControl>
              <HStack>
                <FormLabel marginBottom={0} whiteSpace="nowrap">Major no.</FormLabel>
                <NumberInput
                  {...field}
                  value={newVersion[0]}
                  onChange={e =>
                    setNewVersion(prev => {
                      const newVersion = [e, 0, 0];
                      form.setFieldValue('version', newVersion.join("."));
                      return newVersion
                    })
                  }
                  min={1}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </HStack>
            </FormControl>
          )}
        </Field>
        <Field>
          {({ field, form }) => (
            <FormControl onChange={setField}>
              <HStack>
                <FormLabel marginBottom={0} whiteSpace="nowrap">Minor no.</FormLabel>
                <NumberInput
                  {...field}
                  value={newVersion[1]}
                  onChange={e =>
                    setNewVersion(prev => {
                      const newVersion = [prev[0], e, 0];
                      form.setFieldValue('version', newVersion.join("."));
                      return newVersion
                    })
                  }
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </HStack>
            </FormControl>
          )}
        </Field>
        <Field>
          {({ field, form }) => (
            <FormControl onChange={setField}>
              <HStack>
                <FormLabel marginBottom={0} whiteSpace="nowrap">Patch no.</FormLabel>
                <NumberInput
                  {...field}
                  value={newVersion[2]}
                  onChange={e =>
                    setNewVersion(prev => {
                      const newVersion = [prev[0], prev[1], e];
                      form.setFieldValue('version', newVersion.join("."));
                      return newVersion
                    })
                  }
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </HStack>
            </FormControl>
          )}
        </Field>
      </SlideFade >
      <Button
        colorScheme='gray'
        isLoading={isSubmitting}
        type='submit'
      >
        Submit
      </Button>
    </VStack >
  )
}

export default ({ adminTabHandler }) => {
  const [input, setInput] = useState("");
  const { colorMode } = useColorMode();
  const [patchNotes, setPatchNotes] = useState({});
  const [stage, setStage] = useState('release_ko');
  const toast = useToast();

  const handleInputChange = e => {
    setInput(e.target.value);
  }

  const onSubmit = async (values, actions) => {
    await axios({
      url: '/api/addon/patch-note',
      method: 'post',
      data: { ...values }
    })
    actions.setSubmitting(false)
    adminTabHandler('')
  }

  const getPatchNotes = async () => {
    // TODO: resource name 'patch-note-list' will rename to 'patch-note'
    try {
      const { data: patchNotes } = await axios({ url: '/api/addon/patch-note-list', method: 'get' });

      setPatchNotes(patchNotes);

    } catch (err) {
      const code = err.response.status;
      return toast({
        title: `${code} Error on loading patch notes`,
        position: 'top',
        status: 'error'
      })
    }
  }

  useEffect(() => {
    getPatchNotes();
  }, [])

  return (
    <Formik
      initialValues={{
        stage: 'release_ko', version: '0.0.0', patchNote: ''
      }}
      onSubmit={onSubmit}
    >
      {
        (props) => (
          <Form>
            <HStack
              gap={10}
              divider={<StackDivider borderColor={colorMode === "light" ? "gray.200" : "gray.600"} />}
            >
              <PatchNoteSetting
                stage={stage} setStage={setStage}
                patchNotes={patchNotes}
                setField={props.setFieldValue}
                isSubmitting={props.isSubmitting}
                setInput={setInput}
              />
              <Editor
                input={input} handler={handleInputChange} formikHandler={props.handleChange}
              />
              <Renderer>{input}</Renderer>
            </HStack>
          </Form>
        )
      }
    </Formik>
  )
}