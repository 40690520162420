import React, { useEffect, useState } from 'react';
import { useAppContext } from '../context';
import { Box, Accordion, AccordionButton, AccordionItem, AccordionIcon, AccordionPanel, Tabs, TabList, Tab, TabPanels, TabPanel, Badge, useBreakpointValue } from '@chakra-ui/react';
import axios from 'axios';
import MarkdownRenderer from './MarkdownRenderer';
import { USER_PERMISSION } from '../context/user';
import RestrictedAccess from './RestrictedAccess';

const ADDON_VERSION_KEY = 'addon-version-';

const UpdatedBadge = ({ currentVersion, prevVersion, ...props }) => {
  if (!currentVersion || !prevVersion)
    return (
      <Badge {...props} marginInline={2} colorScheme='red'>Updated</Badge>
    )
  else if (currentVersion && currentVersion !== prevVersion) {
    return (
      <Badge {...props} marginInline={2} colorScheme='red'>Updated</Badge>
    )
  }
}

export default () => {
  const [{
    translations: { addon: translations },
    language, user
  },] = useAppContext();
  const variant = useBreakpointValue({ base: 'sm', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
  const [patchNotes, setPatchNotes] = useState({});
  const [prevAddonVersion, setPrevAddonVersion] = useState({
    release: localStorage.getItem(ADDON_VERSION_KEY + 'release') || '',
    test: localStorage.getItem(ADDON_VERSION_KEY + 'test') || '',
    dev: localStorage.getItem(ADDON_VERSION_KEY + 'dev') || ''
  })
  const [addonVersion, setAddonVersion] = useState({});

  const getPatchNotes = async () => {
    const { data: { releaseKo, releaseEn, test } } = await axios.get("/api/addon/patch-note");
    setPatchNotes({ releaseKo, releaseEn, test });

    const versionRegex = /\d+\.\d+\.\d+/
    const releaseVersion = releaseKo.split("\n")[0].match(versionRegex)[0];
    const testVersion = test.split("\n")[0].match(versionRegex)[0];
    setAddonVersion({
      release: releaseVersion,
      test: testVersion
    })
  }

  const checkPatchNote = (event) => {
    if (event === 0) {
      // expanded accordion
      Object.keys(addonVersion).map(stage => {
        localStorage.setItem(ADDON_VERSION_KEY + stage, addonVersion[stage])
      })
    } else {
      // collapsed accordion
      setPrevAddonVersion(addonVersion)
    }
  }

  useEffect(() => {
    getPatchNotes();
  }, [])

  return (
    <Accordion onChange={checkPatchNote} allowToggle width={variant} mt={10}>
      <AccordionItem>
        <AccordionButton>
          <Box flex='1' textAlign='left'>
            {translations.viewPatchNote}
            {
              // display only one update badge
              Object.keys(addonVersion).length > 0 && (
                (addonVersion.release !== prevAddonVersion.release) ||
                (addonVersion.test !== prevAddonVersion.test)
              ) &&
              <UpdatedBadge />
            }
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <Tabs isFitted>
            <TabList>
              <RestrictedAccess from={USER_PERMISSION.MODELING_TESTER}>
                <Tab>
                  Test
                  <UpdatedBadge
                    currentVersion={addonVersion.test}
                    prevVersion={prevAddonVersion.test}
                  />
                </Tab>
              </RestrictedAccess>
              <Tab>
                Release
                <UpdatedBadge
                  currentVersion={addonVersion.release}
                  prevVersion={prevAddonVersion.release}
                />
              </Tab>
            </TabList>
            <TabPanels>
              {user.permission >= USER_PERMISSION.MODELING_TESTER &&
                <TabPanel overflowY='scroll' maxHeight='60vh'>
                  <MarkdownRenderer>{patchNotes.test}</MarkdownRenderer>
                </TabPanel>
              }
              <TabPanel overflowY='scroll' maxHeight='60vh'>
                {
                  language === 'ko' ?
                    <MarkdownRenderer>{patchNotes.releaseKo}</MarkdownRenderer> :
                    <MarkdownRenderer>{patchNotes.releaseEn}</MarkdownRenderer>
                }
              </TabPanel>
            </TabPanels>
          </Tabs>
        </AccordionPanel>
      </AccordionItem>
    </Accordion >
  )
}