import React from 'react';
import { Box, useColorMode } from '@chakra-ui/react';

export default ({ children, ...rest }) => {
  const { colorMode } = useColorMode();
  return (
    <Box
      p={5}
      shadow="md"
      borderWidth="1px"
      borderRadius={5}
      _hover={{
        background: colorMode === 'light' ? 'gray.100' : 'gray.500',
        cursor: 'pointer',
      }}
      _active={{
        background: colorMode === 'light' ? 'gray.300' : 'gray.400',
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};
