import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Tooltip,
  Image,
  Text,
  useBreakpointValue,
  IconButton,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  VisuallyHiddenInput,
  Link,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { FiPlus } from 'react-icons/fi';
import { Formik, Form, Field } from 'formik';

const Thumbnail = ({ file }) => {
  const [src, setSrc] = useState();
  useEffect(() => {
    if (file) {
      // console.log(file);
      const f = new FileReader();
      f.onloadend = () => {
        setSrc(f.result);
      };
      f.readAsDataURL(file);
    }
  }, [file]);

  return <>{file && <Image src={src} />}</>;
};

const CreateBusinessCard = ({ setNeedRerender }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const onSubmit = async (values, actions) => {
    const name = values.name;
    const file = values.file;

    if (!name || !file) return;

    const formData = new FormData();
    formData.append('file', file);

    await axios({
      url: `/api/hr/business-cards/${name}`,
      method: 'post',
      data: formData,
    });

    actions.setSubmitting(false);
    actions.resetForm();
    setNeedRerender(prev => prev + 1);
    onClose();
    toast({
      description: '업데이트 성공',
      position: 'top',
      status: 'success',
      isClosable: true,
    });
  };

  return (
    <>
      <Button onClick={onOpen} marginBottom={10}>
        <FiPlus pid={1} />
        <Text marginLeft={1}>명함 추가하기</Text>
      </Button>
      <Formik initialValues={{ name: '', file: null }} onSubmit={onSubmit}>
        {props => (
          <Form>
            <Modal
              isOpen={isOpen}
              onClose={() => {
                props.handleReset();
                onClose();
              }}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>명함 추가하기</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Field
                    name="name"
                    validate={value => !value && '이름을 입력하세요.'}
                  >
                    {({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={form.errors.name && form.touched.name}
                        mt={3}
                      >
                        <FormLabel>이름</FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field
                    name="file"
                    validate={value => !value && '명함을 업로드하세요.'}
                  >
                    {({ field, form }) => (
                      <>
                        <FormControl
                          isRequired
                          isInvalid={form.errors.file && form.touched.file}
                          mt={3}
                        >
                          <FormLabel>파일</FormLabel>
                          <input
                            name="file"
                            type="file"
                            onChange={event => {
                              form.setFieldValue(
                                'file',
                                event.currentTarget.files[0]
                              );
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors.file}
                          </FormErrorMessage>
                        </FormControl>
                        <Thumbnail file={form.values.file} />
                      </>
                    )}
                  </Field>
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={props.handleSubmit}
                    type="submit"
                    isLoading={props.isSubmitting}
                    colorScheme="blue"
                    mr={3}
                  >
                    확인
                  </Button>
                  <Button
                    onClick={() => {
                      props.handleReset();
                      onClose();
                    }}
                  >
                    취소
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  );
};

const BusinessCard = ({ card, setNeedRerender }) => {
  const variant = useBreakpointValue({ base: 'mobile', md: 'desktop' });
  const name = card.name;
  const src = card.url;
  const edit = useRef();
  const toast = useToast();

  const updateBusinessCard = async () => {
    edit.current.click();
  };

  const onUploadImage = async event => {
    const file = event.target.files[0];

    if (!name || !file) return;

    const formData = new FormData();
    formData.append('file', file);

    await axios({
      url: `/api/hr/business-cards/${name}`,
      method: 'post',
      data: formData,
    });

    setNeedRerender(prev => prev + 1);
    toast({
      description: '업데이트 성공',
      position: 'top',
      status: 'success',
      isClosable: true,
    });
  };

  return (
    <Tr>
      <Td>{name}</Td>
      <Td>
        <Tooltip
          label={<Image src={src} />}
          placement="bottom-start"
          padding={0}
          bg={'transparent'}
        >
          <Text textDecoration={'underline'} cursor={'pointer'}>
            미리보기
          </Text>
        </Tooltip>
      </Td>
      {variant === 'desktop' && (
        <Td>
          <Link
            href={window.location.protocol + '//' + window.location.host + src}
            isExternal
          >
            {window.location.protocol + '//' + window.location.host + src}
          </Link>
        </Td>
      )}
      <Td>
        <IconButton onClick={updateBusinessCard} icon={<AiFillEdit />} />
        <VisuallyHiddenInput onChange={onUploadImage} type="file" ref={edit} />
      </Td>
    </Tr>
  );
};

export default () => {
  const variant = useBreakpointValue({ base: 'mobile', md: 'desktop' });
  const [cards, setCards] = useState([]);
  const [needRerender, setNeedRerender] = useState(0);
  const toast = useToast();

  const getBusinessCards = async () => {
    try {
      const url = '/api/hr/business-cards';
      const { data: cards } = await axios({ method: 'GET', url });
      setCards(cards);
    } catch (err) {
      toast({
        description: err.response.data,
        position: 'top',
        status: 'error',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getBusinessCards();
  }, [needRerender]);

  return (
    <>
      <CreateBusinessCard setNeedRerender={setNeedRerender} />
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>이름</Th>
            <Th>미리보기</Th>
            {variant === 'desktop' && <Th>URL</Th>}
            <Th>ACTION</Th>
          </Tr>
        </Thead>
        <Tbody>
          {cards.map(card => (
            <BusinessCard
              card={card}
              setNeedRerender={setNeedRerender}
              key={card.name}
            />
          ))}
        </Tbody>
      </Table>
    </>
  );
};
