const type = 'SET_CURRENT_PATH';

const initState = {
  currentPath: '/'
}

const handler = (state, action) => {
  const { currentPath } = action;
  return {
    ...state,
    currentPath
  }
}

export default { type, initState, handler }