import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../context';
import {
  background,
  Box,
  Button,
  Container,
  useColorMode,
  VStack,
  Wrap,
  StackDivider,
} from '@chakra-ui/react';
import User from './tabs/User';
import Addon from './tabs/Addon';
import RestrictedAccess from '../../components/RestrictedAccess';
import { USER_PERMISSION } from '../../context/user';
import Card from '../../components/Card';

const DisplayUser = ({ handler }) => {
  const onClick = () => {
    handler('user');
  };
  return <Card onClick={onClick}>유저 관리</Card>;
};

const DisplayAddon = ({ handler }) => {
  const onClick = () => {
    handler('addon');
  };
  return <Card onClick={onClick}>A.eyes 관리</Card>;
};

const DisplayProject = ({ handler }) => {
  const onClick = () => {
    handler('project');
  };
  return <Card onClick={onClick}>프로젝트 관리</Card>;
};

export default () => {
  const [content, changeContent] = useState();

  const displayAdminView = () => {
    switch (content) {
      case 'user':
        return <User />;
      case 'addon':
        return <Addon adminTabHandler={changeContent} />;
      default:
        return;
    }
  };

  return (
    <RestrictedAccess
      from={USER_PERMISSION.DEV_STAFF}
      include={[USER_PERMISSION.MODELING_MANAGER]}
    >
      <VStack
        p={2}
        divider={<StackDivider borderColor="gray.200" />}
        spacing={4}
        align="stretch"
      >
        <Wrap spacing={5}>
          <RestrictedAccess
            from={USER_PERMISSION.MANAGE_USER}
            include={[USER_PERMISSION.MODELING_MANAGER]}
          >
            <DisplayUser handler={changeContent} />
          </RestrictedAccess>
          <RestrictedAccess from={USER_PERMISSION.MANAGE_PROJECT}>
            <DisplayProject handler={changeContent} />
          </RestrictedAccess>
          <RestrictedAccess from={USER_PERMISSION.MANAGE_A_EYES_PATCH_NOTE}>
            <DisplayAddon handler={changeContent} />
          </RestrictedAccess>
        </Wrap>
        {displayAdminView()}
      </VStack>
    </RestrictedAccess>
  );
};
