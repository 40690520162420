import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, FormErrorMessage, Input, ModalFooter, useToast, Text, Checkbox, CheckboxGroup } from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { USER_PERMISSION } from "../../context/user";
import RestrictedAccess from "../../components/RestrictedAccess";
import { MdNoteAdd } from "react-icons/md"

export default ({ setNeedRerender }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [members, setMembers] = useState([]);
  const toast = useToast();
  const initialRef = React.useRef(null);

  const getPhotographyTeamMember = async () => {
    if (members.length > 0) return;

    try {
      const { data: members } = await axios({
        url: '/api/users?team=photography',
        method: 'get'
      })
      setMembers(members);
    } catch (err) {
      toast({
        description: '촬영팀 멤버를 불러오지 못했습니다.',
        position: 'top',
        status: 'error',
        isClosable: true,
      })
    }
  }

  const onSubmit = async (values, actions) => {
    // alert(JSON.stringify(values, null, 2))
    try {
      const { data } = await axios({
        url: '/api/projects',
        method: 'post',
        data: { ...values },
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'project.info');
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      toast({
        description: '프로젝트 생성 중 에러가 발생했습니다. 잠시 후 다시 시도해주세요.',
        position: 'top',
        status: 'error',
        isClosable: true,
      })
    }
    actions.setSubmitting(false);
    actions.resetForm();
    setNeedRerender(prev => prev + 1);
    onClose();
  }

  useEffect(() => {
    getPhotographyTeamMember();
  }, [])

  return (
    <RestrictedAccess
      from={USER_PERMISSION.PHOTOGRAPHY_STAFF}
      to={USER_PERMISSION.PHOTOGRAPHY_MANAGER}
    >

      <Button onClick={onOpen} variant='outline'>
        <MdNoteAdd size="1.2em" />
        <Text ml={3}>New</Text>
      </Button>

      <Formik
        initialValues={{
          title: '', title_en: '', orderer: '', description: '', address: ''
        }}
        onSubmit={onSubmit}
      >
        {
          props => (
            <Form>
              <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>프로젝트 정보</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <Field
                      name="title"
                      validate={value => !value && "프로젝트명 (한글) 은 필수입니다."}
                    >
                      {({ field, form }) => (
                        <FormControl isRequired isInvalid={form.errors.title && form.touched.title}>
                          <FormLabel>프로젝트명 (한글)</FormLabel>
                          <Input {...field} ref={initialRef} />
                          <FormErrorMessage>{form.errors.title}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="title_en"
                      validate={value => !value && "프로젝트명 (한글) 은 필수입니다."}
                    >
                      {({ field, form }) => (
                        <FormControl mt={3} isRequired isInvalid={form.errors.title_en && form.touched.title_en}>
                          <FormLabel>프로젝트명 (영문)</FormLabel>
                          <Input {...field} />
                          <FormErrorMessage>{form.errors.title_en}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="orderer">
                      {({ field, form }) => (
                        <FormControl mt={3}>
                          <FormLabel>발주사</FormLabel>
                          <Input {...field} />
                        </FormControl>
                      )}
                    </Field>

                    <Field name="photographers">
                      {({ field, form }) => (
                        <FormControl mt={3}>
                          <FormLabel>촬영자</FormLabel>
                          <CheckboxGroup>
                            {members.map(member => (
                              <Checkbox
                                {...field}
                                value={member.id}
                                marginRight={5}
                                key={'photographer-checkbox-' + member.id}
                              >
                                {member.name}
                              </Checkbox>
                            ))}
                          </CheckboxGroup>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="address">
                      {({ field, form }) => (
                        <FormControl mt={3}>
                          <FormLabel>주소</FormLabel>
                          <Input {...field} />
                        </FormControl>
                      )}
                    </Field>

                    <Field name="description">
                      {({ field, form }) => (
                        <FormControl mt={3}>
                          <FormLabel>설명</FormLabel>
                          <Input {...field} />
                        </FormControl>
                      )}
                    </Field>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      onClick={props.handleSubmit}
                      type='submit'
                      isLoading={props.isSubmitting}
                      colorScheme='blue'
                      mr={3}
                    >
                      생성
                    </Button>
                    <Button onClick={onClose}>취소</Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Form>
          )
        }
      </Formik>
    </RestrictedAccess>
  )
}