import { StackDivider, VStack, Wrap } from '@chakra-ui/react';
import React, { useState } from 'react';
import Card from '../../components/Card';
import RestrictedAccess from '../../components/RestrictedAccess';
import { USER_PERMISSION } from '../../context/user';
import BusinessCard from './BusinessCard';

const TABS = {
  BUSINESS_CARD: 0,
};

export default () => {
  const [tab, setTab] = useState(TABS.BUSINESS_CARD);

  const displaySelectedTab = () => {
    switch (tab) {
      case TABS.BUSINESS_CARD:
        return <BusinessCard />;
      default:
        return;
    }
  };

  return (
    <RestrictedAccess
      from={USER_PERMISSION.HR_STAFF}
      to={USER_PERMISSION.HR_MANAGER}
    >
      <VStack
        p={2}
        divider={<StackDivider borderColor="gray.200" />}
        spacing={4}
        align="stretch"
      >
        <Wrap spacing={5}>
          <Card onClick={() => setTab(TABS.BUSINESS_CARD)}>명함 관리</Card>
        </Wrap>
        {displaySelectedTab()}
      </VStack>
    </RestrictedAccess>
  );
};
