import React from 'react';
import { useAppContext } from '../context';
import { Avatar, Heading, HStack } from '@chakra-ui/react';

export default () => {
  const [appContext,] = useAppContext();
  const translations = appContext.translations.home
  const { user } = appContext

  return (
    <>
      <HStack>
        <Avatar src={user.picUrl} />
        <Heading size='lg'>
          {
            appContext.language === 'ko' ?
              user.name + translations.welcome :
              translations.welcome + " " + user.name + "!"
          }
        </Heading>
      </HStack>
    </>
  )
}