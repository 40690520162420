const type = 'SET_USER_INFO';

const initState = {
  user: {},
};

export const USER_PERMISSION = {
  GUEST: 0,

  MODELING_BEGINNER: 10,
  MODELING_STAFF: 11,
  MODELING_SENIOR_STAFF: 12,
  MODELING_ASSISTANT_MANAGER: 13,
  MODELING_TESTER: 14,
  MODELING_MANAGER: 19,

  PHOTOGRAPHY_STAFF: 20,
  PHOTOGRAPHY_MANAGER: 29,

  HR_STAFF: 70,
  HR_MANAGER: 79,

  DEV_STAFF: 80,
  DEV_MANAGER: 89,

  MANAGE_A_EYES_PATCH_NOTE: 90,
  MANAGE_PROJECT: 91,
  MANAGE_USER: 92,

  ADMIN_SUB: 99,
  ADMIN: 100,

  toString: num => {
    for (let key of Object.keys(USER_PERMISSION)) {
      if (key === 'toString') continue;
      if (USER_PERMISSION[key] === num) return key;
    }
  },
};
Object.freeze(USER_PERMISSION);

const handler = (state, action) => {
  const { user } = action;
  return {
    ...state,
    user: {
      name: user.name,
      team: user.team,
      permission: user.permission,
      picUrl: user.picUrl,
    },
  };
};

export default { type, initState, handler };
