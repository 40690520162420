import React, { useEffect, useRef, useState } from 'react';
import { useAppContext } from '../context';
import { Button, Heading, PinInput, PinInputField, useToast, Box, Wrap, WrapItem, Divider } from '@chakra-ui/react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react'
import axios from 'axios';
import PatchNotes from '../components/PatchNotes';
import { USER_PERMISSION } from '../context/user';
import RestrictedAccess from '../components/RestrictedAccess';

const DownloadAddon = () => {

  const download = (e) => {
    let target = e.target.innerHTML.toLowerCase();
    if (target === 'release') target = 'prod';
    window.open(`/api/addon/download/starter/${target}`)
  }

  return (
    <>
      <RestrictedAccess
        from={USER_PERMISSION.MODELING_BEGINNER}
      >
        <Button onClick={download} variant="outline" marginInline={1}>Release</Button>
      </RestrictedAccess>
      <RestrictedAccess
        from={USER_PERMISSION.MODELING_TESTER}
        to={USER_PERMISSION.MODELING_MANAGER}
        include={[
          USER_PERMISSION.DEV_STAFF, USER_PERMISSION.DEV_MANAGER,
          USER_PERMISSION.SUB_ADMIN,
          USER_PERMISSION.MANAGE_A_EYES_PATCH_NOTE
        ]}
      >
        <Button onClick={download} variant="outline" marginInline={1}>Test</Button>
      </RestrictedAccess>
      <RestrictedAccess
        from={USER_PERMISSION.DEV_STAFF}
      >
        <Button onClick={download} variant="outline" marginInline={1}>Dev</Button>
      </RestrictedAccess>
    </>
  )
}

const AccessCode = () => {
  const [{ translations: { addon: translations }, currentPath, user },] = useAppContext();
  const [accessCode, setAccessCode] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const toast = useToast();
  const timerId = useRef();

  const getAccessCode = async () => {
    try {
      const { data: { accessCode } } = await axios({
        method: 'POST',
        url: '/api/addon/asit-access-code'
      });
      setAccessCode(accessCode);
      setIsOpen(true)
      timerId.current = setTimeout(() => {
        setIsOpen(false)
        expireAccessCode()
      }, 30000)
    } catch (err) {
      const code = err.response.status;
      setAccessCode('');
      return toast({
        title: `${code} Error, ${translations.failureGetAccessCode}`,
        position: 'top',
        status: 'error'
      })
    }
  }

  const expireAccessCode = async () => {
    setIsOpen(false)
    clearTimeout(timerId.current)
    await axios({ method: 'DELETE', url: '/api/addon/asit-access-code' })
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(accessCode)
      .then(() => toast({
        title: translations.copied,
        status: 'success',
        position: 'top',
        duration: 3000
      }))
      .catch(() => console.log('failed to copy access code'))
  }

  useEffect(() => {
    return () => {
      expireAccessCode();
    }
  }, [currentPath])

  if (user.permission < USER_PERMISSION.MODELING_BEGINNER) return;
  else return (
    <Popover onOpen={getAccessCode} isOpen={isOpen} >
      <PopoverTrigger>
        <Button variant="outline">{translations.getAccessCode}</Button>
      </PopoverTrigger>
      <PopoverContent textAlign="center">
        <PopoverArrow />
        <PopoverCloseButton onClick={expireAccessCode} />
        <PopoverBody textAlign="center">
          <PinInput value={accessCode}>
            <PinInputField pointerEvents='none' />
            <PinInputField pointerEvents='none' />
            <PinInputField pointerEvents='none' />
            <PinInputField pointerEvents='none' />
          </PinInput>
        </PopoverBody>
        <PopoverFooter>
          <Button onClick={copyToClipboard}>{translations.copy}</Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}

export default () => {
  const [{ translations: { addon: translations } },] = useAppContext();

  return (
    <>
      <Wrap textAlign="center" spacing={5}>
        <WrapItem>
          <Box p={5} shadow='md' borderWidth='1px'>
            <Heading size='sm' mb={3}>{translations.downloadHeading}</Heading>
            <Divider m={3} />
            <DownloadAddon />
          </Box>
        </WrapItem>
        <WrapItem>
          <Box p={5} shadow='md' borderWidth='1px'>
            <Heading size='sm' mb={3}>{translations.getAccessCodeHeading}</Heading>
            <Divider m={3} />
            <AccessCode />
          </Box>
        </WrapItem>
      </Wrap>
      <PatchNotes />
    </>
  )
}