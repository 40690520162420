import React from "react"
import { useAppContext } from "../context";
import { USER_PERMISSION } from "../context/user"


export default ({ from, to, except, include, children }) => {
  /**
   * @params from: allow components if user permission >= from
   * @params to: allow components if user permission <= to
   * @params include: allow components if user permission == elements of include array
   * @params except: NOT allow components if user permission == elements of except array
   */
  const [{ user: { permission: userPermission } },] = useAppContext();

  for (let elem of (except ?? [])) {
    if (userPermission == elem) return;
  }

  for (let elem of (include ?? [])) {
    if (userPermission == elem) return children;
  }

  if (from && to) {
    if (userPermission >= from && userPermission <= to) return children;
  }

  if (from && !to) {
    if (userPermission >= from) return children;
  }

  if (!from && to) {
    if (userPermission <= to) return children;
  }

  if (userPermission === USER_PERMISSION.ADMIN) return children;
}